import { database } from "..";
import Product from "../model/product";
import Proposal from "../model/proposal";
import { Q } from "@nozbe/watermelondb";
import ProposalLineItem from "../model/proposalLineItem";
import { formatDateString } from "../utils";

export type QuickbooksProduct = {
  productServiceName: string;
  description: string;
  type: string;
  price: number;
  taxable: string;
};

export type QuickbooksInvoice = {
  invoiceNumber: string;
  customer: string;
  invoiceDate: string;
  item: string;
  itemDescription: string;
  itemQuantity: number;
  itemRate: number;
  itemAmount: number;
  taxable: string;
  taxRate: string;
};

export async function getAllProducts(): Promise<QuickbooksProduct[]> {
  const productCollection = database.get<Product>("products");
  let query = productCollection.query();
  const dbProducts: Product[] = await query.fetch();
  const products = dbProducts.map((v: Product) => ({
    productServiceName: v.title || "",
    description: v.description || "",
    type: "Service",
    price: v.price || 0.0,
    taxable: v.taxable ? "Yes" : "No",
  }));
  return products;
}

export async function getInvoiceLines(
  startDate?: Date,
  endDate?: Date
): Promise<QuickbooksInvoice[]> {
  const proposalCollection = database.get<Proposal>("proposals");
  let query = proposalCollection.query();
  if (startDate) {
    query = query.extend(Q.where("invoice_date", Q.gte(startDate.getTime())));
  }
  if (endDate) {
    query = query.extend(
      Q.where("invoice_date", Q.lte(endDate.getTime() + 86400 * 1000))
    );
  }
  const proposals: Proposal[] = await query.fetch();
  const proposalLines = await Promise.all(
    proposals.map(async (p: Proposal) => {
      const proposalLineCollection = database.get<ProposalLineItem>(
        "proposal_line_items"
      );
      const lines = await proposalLineCollection
        .query(Q.where("proposal_id", p.id))
        .fetch();
      return Promise.all(
        lines.map(async (l: ProposalLineItem) => {
          const productInfo = await database
            .get<Product>("products")
            .find(l.product?.id || "");
          return {
            invoiceNumber: p.id,
            customer: `${p.firstName} ${p.lastName}`,
            invoiceDate: p.invoiceDate
              ? formatDateString(new Date(p.invoiceDate))
              : "",
            item: l.productName || "",
            itemDescription: l.productNotes || "",
            itemQuantity: l.quantity || 0,
            itemRate: l.unitPrice || 0,
            itemAmount: (l.quantity || 0) * (l.unitPrice || 0),
            taxable: productInfo.taxable ? "Yes" : "No",
            taxRate: `${p.taxRate}%` || "",
          };
        })
      );
    })
  );
  return proposalLines.flat();
}
