import {
  Breadcrumbs,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Link as MUILink,
  InputAdornment,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import pageStyle from "./CustomerDetails.module.css";
import { Delete, Save } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { APITax, deleteTax, emptyTax, getTax, saveTax } from "../api/taxes";

function TaxDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();

  const [tax, setTax] = useState<APITax>(emptyTax());

  useEffect(() => {
    getTax(id || undefined).then((v) => setTax(v));
  }, [id]);

  const [isModified, setIsModified] = useState(false);

  // Handle changes
  const changeTax = (change: Partial<APITax>) => {
    setIsModified(true);
    setTax({ ...tax, ...change });
  };

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  const handleSave = async () => {
    const t = await saveTax(tax);
    setTax(t);
    setSearchParams({ id: t.id || "" });
    setIsModified(false);
  };

  const handleDelete = async () => {
    if (id) {
      await deleteTax(id);
    }
    navigate("/taxes");
  };

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <Breadcrumbs>
            <MUILink
              component={Link}
              underline="hover"
              color="inherit"
              to="/taxes"
            >
              Taxes
            </MUILink>
            <Typography color="text.primary">Details</Typography>
          </Breadcrumbs>

          <div className={pageStyle.customerDetailPanel}>
            <div>
              <form
                className={pageStyle.editCustomerForm}
                noValidate
                autoComplete="off"
              >
                <FormControl>
                  <InputLabel htmlFor="titleInput">Title</InputLabel>
                  <OutlinedInput
                    value={tax.title}
                    onChange={(e) => changeTax({ title: e.target.value })}
                    id="titleInput"
                    label="Title"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="percentRateInput">
                    Percent Rate
                  </InputLabel>
                  <OutlinedInput
                    value={tax.percentRate}
                    onChange={(e) =>
                      changeTax({ percentRate: Number(e.target.value) })
                    }
                    type="number"
                    id="percentRateInput"
                    label="Percent Rate"
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                </FormControl>
              </form>
              <div className={utilStyle.controlHeader}>
                <div className={utilStyle.controlHeaderButtonContainer}>
                  <Button
                    color="success"
                    variant="contained"
                    startIcon={<Save />}
                    disabled={!canSave()}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    startIcon={<Delete />}
                    disabled={!id}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default TaxDetailsPage;
