import { database } from "..";
import Customer from "../model/customer";
import Photo from "../model/photo";
import Proposal from "../model/proposal";
import { ActivityPhoto, ActivityProposal, APIActivity } from "./activities";
import { Q } from "@nozbe/watermelondb";

export type APICustomer = {
  id?: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneExt: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  notes: string;
};

export function emptyCustomer(): APICustomer {
  return {
    firstName: "",
    lastName: "",
    phone: "",
    phoneExt: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "NY",
    zip: "",
    notes: "",
  };
}

function dbCustomerToAPICustomer(c: Customer): APICustomer {
  return {
    id: c.id,
    firstName: c.firstName || "",
    lastName: c.lastName || "",
    phone: c.phone || "",
    phoneExt: c.phoneExt || "",
    email: c.email || "",
    addressLine1: c.addressLine1 || "",
    addressLine2: c.addressLine2 || "",
    city: c.city || "",
    state: c.state || "",
    zip: c.zip || "",
    notes: c.notes || "",
  };
}

export async function getCustomerActivities(
  customerID: string
): Promise<APIActivity[]> {
  const photos: ActivityPhoto[] = (
    await database
      .get<Photo>("photos")
      .query(Q.where("customer_id", customerID))
      .fetch()
  ).map((p) => ({
    type: "photo",
    id: p.id || "",
    photoData: p.photoData || "",
    createdAt: p.createdAt || 0,
  }));
  const proposals: ActivityProposal[] = (
    await database
      .get<Proposal>("proposals")
      .query(Q.where("customer_id", customerID))
      .fetch()
  ).map((p) => ({
    type: "proposal",
    id: p.id || "",
    createdAt: p.createdAt || 0,
    totalAmount: p.totalAmount || 0,
    status: p.status || "",
  }));
  return [...photos, ...proposals].sort(
    (a, b) => (a.createdAt || 0) - (b.createdAt || 0)
  );
}

export async function getCustomer(customerID?: string): Promise<APICustomer> {
  if (!customerID) {
    return emptyCustomer();
  }
  const customer = await database.get<Customer>("customers").find(customerID);
  return dbCustomerToAPICustomer(customer);
}

export async function saveCustomer(
  customer: APICustomer
): Promise<APICustomer> {
  const databaseWrite = (c: Customer) => {
    c.firstName = customer.firstName;
    c.lastName = customer.lastName;
    c.phone = customer.phone;
    c.phoneExt = customer.phoneExt;
    c.email = customer.email;
    c.addressLine1 = customer.addressLine1;
    c.addressLine2 = customer.addressLine2;
    c.city = customer.city;
    c.state = customer.state;
    c.zip = customer.zip;
    c.notes = customer.notes;
  };
  let savedCustomerID: string | undefined = customer.id;
  if (!customer.id) {
    await database.write(async () => {
      const savedCustomer = await database
        .get<Customer>("customers")
        .create(databaseWrite);
      savedCustomerID = savedCustomer.id;
    });
  } else {
    await database.write(async () => {
      const savedCustomer = await database
        .get<Customer>("customers")
        .find(customer.id || "");
      await savedCustomer.update(databaseWrite);
    });
  }
  return getCustomer(savedCustomerID);
}

export async function deleteCustomer(customerId: string): Promise<void> {
  await database.write(async () => {
    const customer = await database.get<Customer>("customers").find(customerId);
    await customer.markAsDeleted();
  });
}

export type APICustomerFilter = {
  createdStart?: number;
  createdEnd?: number;
  search?: string;
};

export async function getAllCustomers(
  filters?: APICustomerFilter
): Promise<APICustomer[]> {
  const customerCollection = database.get<Customer>("customers");
  let query = customerCollection.query();
  if (filters?.createdStart) {
    query = query.extend(Q.where("created_at", Q.gte(filters.createdStart)));
  }
  if (filters?.createdEnd) {
    query = query.extend(
      Q.where("created_at", Q.lte(filters.createdEnd + 86400 * 1000))
    );
  }
  if (filters?.search) {
    query = query.extend(
      Q.or(
        Q.where(
          "first_name",
          Q.like(`%${Q.sanitizeLikeString(filters.search)}%`)
        ),
        Q.where(
          "last_name",
          Q.like(`%${Q.sanitizeLikeString(filters.search)}%`)
        )
      )
    );
  }

  const dbCustomers: Customer[] = await query.fetch();
  const customers: APICustomer[] = dbCustomers.map(dbCustomerToAPICustomer);
  return customers;
}
