import { states } from "./constants";
import apiRequest from "./requests";

function formatMoney(amount: number): string {
  return `$${amount}`;
}

function formatDateString(d: Date): string {
  return `${d.getMonth()}/${d.getDay()}/${d.getFullYear()}`;
}

export { apiRequest, formatMoney, states, formatDateString };
