import {
  Assignment,
  Menu,
  Person,
  History,
  Inventory,
  CurrencyExchange,
  Settings,
  Sync,
  AccountBalance,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { syncDatabase } from "../utils/offlineSync";

function TopNav() {
  const nav = useNavigate();
  const [drawerOpen, toggleDrawerOpen] = useState(false);
  const token = sessionStorage.getItem("token");
  if (token === null) {
    nav("/login");
    return <></>;
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => toggleDrawerOpen(!drawerOpen)}
          >
            <Menu />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="sync"
            sx={{ mr: 2 }}
            onClick={() => syncDatabase(token)}
          >
            <Sync />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer open={drawerOpen} onClose={() => toggleDrawerOpen(false)}>
        <List sx={{ minWidth: 300 }}>
          <ListItemButton
            component={Link}
            to="/customers"
            onClick={() => toggleDrawerOpen(false)}
          >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/proposals"
            onClick={() => toggleDrawerOpen(false)}
          >
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Proposals" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/activity"
            onClick={() => toggleDrawerOpen(false)}
          >
            <ListItemIcon>
              <History />
            </ListItemIcon>
            <ListItemText primary="Activity" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/products"
            onClick={() => toggleDrawerOpen(false)}
          >
            <ListItemIcon>
              <Inventory />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/taxes"
            onClick={() => toggleDrawerOpen(false)}
          >
            <ListItemIcon>
              <CurrencyExchange />
            </ListItemIcon>
            <ListItemText primary="Taxes" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/settings"
            onClick={() => toggleDrawerOpen(false)}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/quickbooks"
            onClick={() => toggleDrawerOpen(false)}
          >
            <ListItemIcon>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText primary="Quickbooks" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}

export default TopNav;
