import { Button } from "@mui/material";
import pageStyle from "./LoginPage.module.css";
import { useAuth0 } from "@auth0/auth0-react";

function LoginPage() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className={pageStyle.loginContainer}>
      <Button onClick={() => loginWithRedirect()} variant="contained">
        Sign In
      </Button>
    </div>
  );
}

export default LoginPage;
