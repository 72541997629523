import { Model } from "@nozbe/watermelondb";
import {
  text,
  date,
  readonly,
  field,
  relation,
} from "@nozbe/watermelondb/decorators";
import Tax from "./tax";
import Customer from "./customer";

export default class Proposal extends Model {
  static table = "proposals";

  @relation("customers", "customer_id") customer?: Customer;
  @text("customer_first_name") firstName?: string;
  @text("customer_last_name") lastName?: string;
  @text("customer_phone") phone?: string;
  @text("customer_phone_ext") phoneExt?: string;
  @text("customer_email") email?: string;
  @text("customer_address_line_1") addressLine1?: string;
  @text("customer_address_line_2") addressLine2?: string;
  @text("customer_city") city?: string;
  @text("customer_state") state?: string;
  @text("customer_zip") zip?: string;
  @text("service_address_line_1") serviceAddressLine1?: string;
  @text("service_address_line_2") serviceAddressLine2?: string;
  @text("service_city") serviceCity?: string;
  @text("service_state") serviceState?: string;
  @text("service_zip") serviceZip?: string;
  @text("service_notes") serviceNotes?: string;

  @text("tax_name") taxName?: string;
  @field("tax_rate") taxRate?: number;
  @relation("taxes", "tax_id") tax?: Tax;

  @field("subtotal_amount") subtotalAmount?: number;
  @field("tax_amount") taxAmount?: number;
  @field("total_amount") totalAmount?: number;

  @text("status") status?: string;
  @date("invoice_date") invoiceDate?: number;
  @date("invoice_due_date") invoiceDueDate?: number;

  @text("terms") terms?: string;
  @text("signature") signature?: string;

  @readonly @date("created_at") createdAt?: number;
  @readonly @date("updated_at") updatedAt?: number;
}
