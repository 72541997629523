import {
  Breadcrumbs,
  Button,
  Divider,
  Typography,
  Link as MUILink,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  List,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import { Add, Backspace, Close, FactCheck, Save } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import pageStyle from "./ProposalDetails.module.css";
import { formatMoney, states } from "../utils";
import ProposalLineItem from "../components/ProposalLineItem";
import { Image as KImage, Layer, Line, Stage } from "react-konva";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APICustomer, getAllCustomers } from "../api/customers";
import {
  APIProposal,
  APIProposalLine,
  emptyProposal,
  emptyProposalLine,
  getProposal,
  saveProposal,
  strToStatus,
} from "../api/proposals";
import { APITax, getAllTaxes, getTax } from "../api/taxes";
import { APIProduct, getAllProducts, getProduct } from "../api/products";

function ProposalDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const cid = searchParams.get("cid");

  const [proposal, setProposal] = useState<APIProposal>(emptyProposal());
  const [customersData, setCustomersData] = useState<APICustomer[]>([]);
  const [taxesData, setTaxesData] = useState<APITax[]>([]);
  const [productsData, setProductsData] = useState<APIProduct[]>([]);
  useEffect(() => {
    getAllCustomers().then((v) => setCustomersData(v));
    getAllTaxes().then((v) => setTaxesData(v));
    getAllProducts().then((v) => setProductsData(v));
  }, []);

  useEffect(() => {
    getProposal(id || undefined, cid || undefined).then((v) => setProposal(v));
  }, [id, cid]);

  const updateProposal = async (update: Partial<APIProposal>) => {
    const updated = { ...proposal, ...update };
    if (update.taxId) {
      const t = await getTax(update.taxId);
      updated.taxName = t.title;
      updated.taxRate = t.percentRate;
    }
    if (update.lines || update.taxId) {
      let subtotal = 0.0;
      let taxTotal = 0.0;
      const products = await Promise.all(
        updated.lines.map((v) => getProduct(v.productId))
      );
      for (const i in updated.lines) {
        const partSubtotal =
          updated.lines[i].quantity * updated.lines[i].unitPrice;
        if (products[i].taxable) {
          taxTotal += partSubtotal * (updated.taxRate / 100);
        }
        subtotal += partSubtotal;
      }
      updated.subtotalAmount = subtotal;
      updated.taxAmount = taxTotal;
      updated.totalAmount = subtotal + taxTotal;
    }
    setProposal(updated);
    setIsModified(true);
  };

  const newLine = () => {
    updateProposal({
      lines: [
        ...proposal.lines,
        { ...emptyProposalLine(), id: `local_${Date.now()}` },
      ],
    });
  };

  const [deletedLineIds, setDeletedLineIds] = useState<string[]>([]);
  const removeLine = (i: number) => () => {
    if (proposal.lines[i].id && !proposal.lines[i].id?.startsWith("local_")) {
      setDeletedLineIds([...deletedLineIds, proposal.lines[i].id!]);
    }
    updateProposal({ lines: proposal.lines.filter((_, s) => s !== i) });
  };

  const updateLine =
    (i: number) => async (update: Partial<APIProposalLine>) => {
      const newLine = { ...proposal.lines[i], ...update };
      if (update.productId) {
        const p = await getProduct(update.productId);
        newLine.productName = p.title;
        newLine.productNotes = p.description;
        newLine.unitPrice = p.price;
      }
      const newLines = proposal.lines;
      newLines[i] = newLine;
      updateProposal({ lines: newLines });
    };

  const [isModified, setIsModified] = useState(false);

  const canSave = () => {
    // TODO: Add validation
    return isModified || signatureModified || lines.length !== 0;
  };

  const save = async () => {
    const op = {
      ...proposal,
      signature: stageRef.current.toDataURL(),
      lines: proposal.lines.map((v) => ({
        ...v,
        id: v.id?.startsWith("local_") ? undefined : v.id,
      })),
    };

    let p = await saveProposal(op, deletedLineIds);
    setSignatureModified(false);
    setLines([]);
    setIsModified(false);
    setSearchParams({ id: p.id || "" });
    setProposal(p);
  };

  useEffect(() => {
    const file = proposal.signature;
    if (!file) return;
    imageObj.src = file;
  }, [proposal.signature]);

  const contractModalRef = useRef<HTMLDialogElement | null>(null);
  const [termsMultiline, setTermsMultiline] = useState(false);
  const openContractModal = () => {
    contractModalRef.current?.showModal();
    setTermsMultiline(true);
  };
  const closeContractModal = () => {
    contractModalRef.current?.close();
    setTermsMultiline(false);
  };

  const [signatureModified, setSignatureModified] = useState(false);
  const [kImg, setKImg] = useState<any>(null);
  const imageObj = new Image();

  const loadImage = () => {
    if (!imageObj) return;
    setKImg(imageObj);
  };

  imageObj.onload = loadImage;
  imageObj.onchange = loadImage;

  const tool = "pen";
  const [lines, setLines] = useState<{ tool: string; points: number[] }[]>([]);
  const isDrawing = useRef(false);
  const stageRef = useRef<any>(null);

  const handleMouseDown = (e: any) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e: any) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <Breadcrumbs>
            <MUILink
              component={Link}
              underline="hover"
              color="inherit"
              to="/proposals"
            >
              Proposals
            </MUILink>
            <Typography color="text.primary">Details</Typography>
          </Breadcrumbs>
          <div className={pageStyle.proposalDetailContainer}>
            <div className={pageStyle.proposalDetailCustomerContainer}>
              <FormControl>
                <InputLabel>Customer</InputLabel>
                <Select
                  value={proposal.customerId}
                  onChange={(e) =>
                    updateProposal({ customerId: e.target.value })
                  }
                  label="Customer"
                >
                  {customersData.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.firstName} {c.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider />
              <Typography>{proposal.addressLine1}</Typography>
              <Typography>{proposal.addressLine2}</Typography>
              <Typography>
                {proposal.city}, {proposal.state} {proposal.zip}
              </Typography>
              <Divider />
              <Typography>
                {proposal.phone}
                {proposal.phoneExt ? `x${proposal.phoneExt}` : ""}
              </Typography>
              <Divider />
              <FormControl>
                <InputLabel htmlFor="statusInput">Status</InputLabel>
                <Select
                  id="statusInput"
                  label="Status"
                  value={proposal.status}
                  onChange={(e) =>
                    updateProposal({
                      status: strToStatus(e.target.value),
                    })
                  }
                >
                  <MenuItem value={"LEAD"}>Lead</MenuItem>
                  <MenuItem value={"QUOTED"}>Quoted</MenuItem>
                  <MenuItem value={"WON"}>Won</MenuItem>
                  <MenuItem value={"DONE"}>Done</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(e) =>
                      updateProposal({
                        invoiceDate: e ? e.unix() * 1000 : undefined,
                      })
                    }
                    value={
                      proposal.invoiceDate
                        ? dayjs(proposal.invoiceDate)
                        : undefined
                    }
                    label="Invoice Date"
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(e) =>
                      updateProposal({
                        invoiceDueDate: e ? e.unix() * 1000 : undefined,
                      })
                    }
                    value={
                      proposal.invoiceDueDate
                        ? dayjs(proposal.invoiceDueDate)
                        : undefined
                    }
                    label="Invoice Due Date"
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div>
              <form
                className={pageStyle.editProposalForm}
                noValidate
                autoComplete="off"
              >
                <FormControl>
                  <InputLabel htmlFor="addressLine1Input">
                    Address Line 1
                  </InputLabel>
                  <OutlinedInput
                    value={proposal.serviceAddressLine1}
                    onChange={(e) =>
                      updateProposal({ serviceAddressLine1: e.target.value })
                    }
                    id="addressLine1Input"
                    label="Address Line 1"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="addressLine2Input">
                    Address Line 2
                  </InputLabel>
                  <OutlinedInput
                    value={proposal.serviceAddressLine2}
                    onChange={(e) =>
                      updateProposal({ serviceAddressLine2: e.target.value })
                    }
                    id="addressLine2Input"
                    label="Address Line 2"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="cityInput">City</InputLabel>
                  <OutlinedInput
                    value={proposal.serviceCity}
                    onChange={(e) =>
                      updateProposal({ serviceCity: e.target.value })
                    }
                    id="cityInput"
                    label="City"
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="stateInput">State</InputLabel>
                  <Select
                    id="stateInput"
                    label="State"
                    value={proposal.serviceState}
                    onChange={(e) =>
                      updateProposal({ serviceState: e.target.value })
                    }
                  >
                    {states.map(({ name, abbreviation }) => (
                      <MenuItem key={abbreviation} value={abbreviation}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="zipInput">Zip Code</InputLabel>
                  <OutlinedInput
                    value={proposal.serviceZip}
                    onChange={(e) =>
                      updateProposal({ serviceZip: e.target.value })
                    }
                    id="zipInput"
                    label="Zip Code"
                  />
                </FormControl>
                <Divider />
                <FormControl>
                  <InputLabel htmlFor="notesInput">Notes</InputLabel>
                  <OutlinedInput
                    value={proposal.serviceNotes}
                    onChange={(e) =>
                      updateProposal({ serviceNotes: e.target.value })
                    }
                    multiline
                    minRows={3}
                    id="notesInput"
                    label="Notes"
                  />
                </FormControl>
              </form>
            </div>
            <div className={pageStyle.proposalDetailLineItems}>
              <List>
                {proposal.lines.map((line, i) => {
                  return (
                    <ProposalLineItem
                      removeLine={removeLine(i)}
                      updateLine={updateLine(i)}
                      products={productsData}
                      line={line}
                      key={line.id}
                    />
                  );
                })}
              </List>
              <Button
                color="success"
                variant="outlined"
                startIcon={<Add />}
                onClick={newLine}
              >
                Add Line
              </Button>
            </div>
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Subtotal</TableCell>
                    <TableCell>
                      {formatMoney(proposal.subtotalAmount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "100%" }}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel>Tax</InputLabel>
                        <Select
                          onChange={(e) =>
                            updateProposal({ taxId: e.target.value })
                          }
                          value={proposal.taxId}
                          label="Tax"
                          sx={{ width: "100%" }}
                        >
                          {taxesData.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                              {t.title} ({t.percentRate}%)
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>{formatMoney(proposal.taxAmount)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>{formatMoney(proposal.totalAmount)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.controlHeaderButtonContainer}>
              <Button
                color="success"
                variant="contained"
                startIcon={<Save />}
                disabled={!canSave()}
                onClick={save}
              >
                Save
              </Button>
              <Button
                variant="contained"
                startIcon={<FactCheck />}
                disabled={!id}
                onClick={openContractModal}
              >
                Contract
              </Button>
            </div>
          </div>
        </main>
      </div>
      <dialog ref={contractModalRef} className={pageStyle.termsContainer}>
        <IconButton
          className={pageStyle.closeButton}
          onClick={closeContractModal}
        >
          <Close />
        </IconButton>
        <div>
          <form
            className={pageStyle.editProposalForm}
            noValidate
            autoComplete="off"
          >
            <FormControl>
              <InputLabel htmlFor="termsInput">Terms and Conditions</InputLabel>
              <OutlinedInput
                onChange={(e) => updateProposal({ terms: e.target.value })}
                value={proposal.terms}
                multiline={termsMultiline}
                fullWidth
                rows={15}
                id="termsInput"
                label="Terms and Conditions"
              />
            </FormControl>
          </form>
        </div>
        <div id="canvasContainer" className={pageStyle.signature}>
          <Stage
            width={500}
            height={100}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchMove={handleMouseMove}
            onTouchEnd={handleMouseUp}
            ref={stageRef}
          >
            <Layer>
              <KImage image={kImg} width={500} height={100} />
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke="#000"
                  strokeWidth={5}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={
                    line.tool === "eraser" ? "destination-out" : "source-over"
                  }
                />
              ))}
            </Layer>
          </Stage>
        </div>
        <div className={utilStyle.controlHeader}>
          <div className={utilStyle.controlHeaderButtonContainer}>
            <Button
              color="success"
              variant="contained"
              startIcon={<Save />}
              disabled={!canSave()}
              onClick={save}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              startIcon={<Backspace />}
              onClick={() => {
                setLines([]);
                setKImg(null);
                setSignatureModified(true);
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </dialog>
    </div>
  );
}

export default ProposalDetailsPage;
