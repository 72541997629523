import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  ListItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import pageStyle from "../pages/ProposalDetails.module.css";
import { APIProposalLine } from "../api/proposals";
import { APIProduct } from "../api/products";

export default function ProposalLineItem(props: {
  line: APIProposalLine;
  products: APIProduct[];
  updateLine: (update: Partial<APIProposalLine>) => void;
  removeLine: () => void;
}) {
  const { updateLine, removeLine, line, products } = props;

  return (
    <ListItem>
      <div className={pageStyle.proposalLineItem}>
        <div className={pageStyle.editProposalForm}>
          <FormControl>
            <InputLabel>Product</InputLabel>
            <Select
              onChange={(e) => updateLine({ productId: e.target.value })}
              value={line.productId}
              label="Product"
            >
              {products.map((p) => (
                <MenuItem value={p.id}>{p.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="notesInput">Notes</InputLabel>
            <OutlinedInput
              value={line.productNotes}
              onChange={(e) => updateLine({ productNotes: e.target.value })}
              multiline
              minRows={3}
              id="notesInput"
              label="Notes"
            />
          </FormControl>
        </div>
        <div className={pageStyle.proposalLineItemModifiers}>
          <FormControl>
            <InputLabel>Quantity</InputLabel>
            <OutlinedInput
              type="number"
              value={line.quantity}
              onChange={(e) => updateLine({ quantity: Number(e.target.value) })}
              label="Quantity"
            />
          </FormControl>
          <FormControl>
            <InputLabel>Price</InputLabel>
            <OutlinedInput
              type="number"
              value={line.unitPrice}
              onChange={(e) =>
                updateLine({ unitPrice: Number(e.target.value) })
              }
              label="Price"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
          <IconButton
            className={pageStyle.deleteLineButton}
            onClick={removeLine}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
    </ListItem>
  );
}
