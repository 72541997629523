import { FormEvent, useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link, useSearchParams } from "react-router-dom";
import {
  List,
  Avatar,
  ListItemText,
  ListItemAvatar,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Button,
  ListItemButton,
  Menu,
  ListItem,
} from "@mui/material";
import { Add, Search, FilterList } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  APICustomer,
  APICustomerFilter,
  getAllCustomers,
} from "../api/customers";

function CustomerListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = {
    createdStart: searchParams.get("createdStart")
      ? Number(searchParams.get("createdStart"))
      : undefined,
    createdEnd: searchParams.get("createdEnd")
      ? Number(searchParams.get("createdEnd"))
      : undefined,
    search: searchParams.get("search") || undefined,
  };

  const [filter, setFilter] = useState<APICustomerFilter>(initialFilter);
  const updateFilter = (update: Partial<APICustomerFilter>) => {
    const newFilter = { ...filter, ...update };
    setFilter(newFilter);
    const n = Object.fromEntries(
      Object.entries(newFilter)
        .filter(([_, v]) => v !== undefined)
        .map(([k, v]) => [k, JSON.stringify(v)])
    );
    setSearchParams(n);
  };

  const [customersData, setCustomerData] = useState<APICustomer[]>([]);
  useEffect(() => {
    getAllCustomers(filter).then((v) => setCustomerData(v));
  }, [filter]);

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  // Dropdown menus
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const filterOpen = !!filterAnchorEl;

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.searchBar}>
              <form onSubmit={handleSearchSubmit}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="searchInput">Search</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="searchInput"
                    value={filter.search}
                    onChange={(e) => {
                      updateFilter({ search: e.target.value || undefined });
                    }}
                    label="Search"
                    endAdornment={
                      <IconButton type="submit">
                        <Search />
                      </IconButton>
                    }
                  />
                </FormControl>
              </form>
            </div>
            <div className={utilStyle.controlHeaderButtonContainer}>
              <Button
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                variant="outlined"
                startIcon={<FilterList />}
              >
                Filter
              </Button>
              <Menu
                anchorEl={filterAnchorEl}
                open={filterOpen}
                onClose={() => setFilterAnchorEl(null)}
                sx={{ width: 400, maxWidth: "100%" }}
              >
                <List sx={{ width: 400, maxWidth: "100%" }}>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>Created</ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={(e) =>
                              updateFilter({
                                createdStart: e ? e.unix() * 1000 : undefined,
                              })
                            }
                            value={
                              filter.createdStart
                                ? dayjs(filter.createdStart)
                                : undefined
                            }
                            label="Start Date"
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker
                            onChange={(e) =>
                              updateFilter({
                                createdEnd: e ? e.unix() * 1000 : undefined,
                              })
                            }
                            value={
                              filter.createdEnd
                                ? dayjs(filter.createdEnd)
                                : undefined
                            }
                            label="End Date"
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>
                      Last Activity
                    </ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker label="Start Date" />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker label="End Date" />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                </List>
              </Menu>
              {/* <Button variant="outlined" startIcon={<Download />}>
                Export
              </Button> */}
              <Button
                component={Link}
                to="/customers/details"
                color="success"
                variant="contained"
                startIcon={<Add />}
              >
                New Customer
              </Button>
            </div>
          </div>
          <List>
            {customersData.map((customer) => (
              <ListItemButton
                component={Link}
                to={`/customers/details?id=${customer.id}`}
                key={customer.id}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#606060" }}>
                    {customer.firstName && customer.firstName[0].toUpperCase()}
                    {customer.lastName && customer.lastName[0].toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${customer.firstName} ${customer.lastName}`}
                  secondary={`${customer.addressLine1} ${customer.city}${
                    customer.city && customer.state && ", "
                  }${customer.state}`}
                />
              </ListItemButton>
            ))}
          </List>
        </main>
      </div>
    </div>
  );
}

export default CustomerListPage;
