import { Button, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import TopNav from "../components/TopNav";
import pageStyle from "./CustomerDetails.module.css";
import { Save } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { ChangeEvent, useEffect, useState } from "react";
import { getDefaultTerms, setDefaultTerms } from "../api/settings";

function SettingsPage() {
  useEffect(() => {
    getDefaultTerms().then((v) => setTerms(v));
  }, []);

  const [isModified, setIsModified] = useState(false);

  // Form fields
  const [terms, setTerms] = useState("");

  // Handle changes
  const changeTerms = (change: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true);
    setTerms(change.target.value);
  };

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  const handleSave = async () => {
    // handle create case
    await setDefaultTerms(terms);

    setIsModified(false);
  };
  return (
    <div className="page">
      <TopNav />
      <main>
        <form
          className={pageStyle.editCustomerForm}
          noValidate
          autoComplete="off"
        >
          <FormControl>
            <InputLabel htmlFor="termsInput">Terms and Conditions</InputLabel>
            <OutlinedInput
              onChange={changeTerms}
              value={terms}
              multiline
              minRows={3}
              id="termsInput"
              label="Terms and Conditions"
            />
          </FormControl>
        </form>
        <div className={utilStyle.controlHeader}>
          <div className={utilStyle.controlHeaderButtonContainer}>
            <Button
              disabled={!canSave()}
              onClick={handleSave}
              color="success"
              variant="contained"
              startIcon={<Save />}
            >
              Save
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SettingsPage;
