import { database } from "..";
import Customer from "../model/customer";
import Photo from "../model/photo";

export type APIPhoto = {
  id?: string;
  photoData: string;
  customerID: string;
};

function emptyPhoto(): APIPhoto {
  return {
    photoData: "",
    customerID: "",
  };
}

function dbPhotoToAPIPhoto(photo: Photo): APIPhoto {
  return {
    id: photo.id,
    customerID: photo.customer?.id || "",
    photoData: photo.photoData || "",
  };
}

export async function getPhoto(photoID?: string): Promise<APIPhoto> {
  if (photoID === undefined) {
    return emptyPhoto();
  }
  const photo = await database.get<Photo>("photos").find(photoID);
  return dbPhotoToAPIPhoto(photo);
}

export async function savePhoto(photo: APIPhoto): Promise<APIPhoto> {
  const cr = await database
    .get<Customer>("customers")
    .find(photo.customerID || "");
  const databaseWrite = (p: Photo) => {
    const c: any = p.customer;
    c.set(cr);
    p.photoData = photo.photoData;
  };
  let savedPhotoID: string | undefined = photo.id;
  if (!photo.id) {
    await database.write(async () => {
      const savedPhoto = await database
        .get<Photo>("photos")
        .create(databaseWrite);
      savedPhotoID = savedPhoto.id;
    });
  } else {
    await database.write(async () => {
      const savedPhoto = await database
        .get<Photo>("photos")
        .find(photo.id || "");
      await savedPhoto.update(databaseWrite);
    });
  }
  return getPhoto(savedPhotoID);
}
