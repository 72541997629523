import {
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  OutlinedInput,
  Select,
  Typography,
  Link as MUILink,
} from "@mui/material";
import TopNav from "../components/TopNav";
import utilStyle from "./Utils.module.css";
import pageStyle from "./CustomerDetails.module.css";
import {
  AccessTime,
  Add,
  Assignment,
  Delete,
  Photo as MUIPhoto,
  Save,
} from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import { useState, useEffect } from "react";
import { formatMoney, states } from "../utils";
import {
  APICustomer,
  deleteCustomer,
  emptyCustomer,
  getCustomer,
  getCustomerActivities,
  saveCustomer,
} from "../api/customers";
import { APIActivity } from "../api/activities";

function CustomerDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    getCustomerActivities(id).then((v) => setCustomerActivity(v));
    getCustomer(id).then((v) => setCustomer(v));
  }, [id]);

  const [customerActivity, setCustomerActivity] = useState<APIActivity[]>([]);
  const [customer, setCustomer] = useState<APICustomer>(emptyCustomer());

  // Dropdown menus
  const [newActivityAnchorEl, setNewActivityAnchorEl] =
    useState<null | HTMLElement>(null);
  const newActivityOpen = !!newActivityAnchorEl;

  // Handle changes
  const [isModified, setIsModified] = useState(false);
  const updateCustomer = (change: Partial<APICustomer>) => {
    setIsModified(true);
    setCustomer({ ...customer, ...change });
  };

  const canSave = () => {
    // TODO: Add validation
    return isModified;
  };

  const handleSave = async () => {
    const c = await saveCustomer(customer);
    setCustomer(c);
    setSearchParams({ id: c.id || "" });
    setIsModified(false);
  };

  const handleDelete = async () => {
    if (id) {
      await deleteCustomer(id);
    }
    navigate("/customers");
  };

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <Breadcrumbs>
            <MUILink
              component={Link}
              underline="hover"
              color="inherit"
              to="/customers"
            >
              Customers
            </MUILink>
            <Typography color="text.primary">Details</Typography>
          </Breadcrumbs>

          <div className={pageStyle.customerDetailContainer}>
            <div className={pageStyle.customerDetailPanel}>
              <div>
                <form
                  className={pageStyle.editCustomerForm}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl>
                    <InputLabel htmlFor="firstNameInput">First Name</InputLabel>
                    <OutlinedInput
                      value={customer.firstName}
                      onChange={(e) =>
                        updateCustomer({ firstName: e.target.value })
                      }
                      id="firstNameInput"
                      label="First Name"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="lastNameInput">Last Name</InputLabel>
                    <OutlinedInput
                      value={customer.lastName}
                      onChange={(e) =>
                        updateCustomer({ lastName: e.target.value })
                      }
                      id="lastNameInput"
                      label="Last Name"
                    />
                  </FormControl>
                  <Divider />
                  <FormControl>
                    <MuiTelInput
                      defaultCountry="US"
                      value={customer.phone}
                      onChange={(e) => updateCustomer({ phone: e })}
                      id="PhoneNumberInput"
                      label="Phone Number"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="phoneExtInput">
                      Phone Extension
                    </InputLabel>
                    <OutlinedInput
                      value={customer.phoneExt}
                      onChange={(e) =>
                        updateCustomer({ phoneExt: e.target.value })
                      }
                      id="phoneExtInput"
                      label="Phone Extension"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="emailInput">Email</InputLabel>
                    <OutlinedInput
                      value={customer.email}
                      onChange={(e) =>
                        updateCustomer({ email: e.target.value })
                      }
                      id="emailInput"
                      label="Email"
                    />
                  </FormControl>
                  <Divider />
                  <FormControl>
                    <InputLabel htmlFor="addressLine1Input">
                      Address Line 1
                    </InputLabel>
                    <OutlinedInput
                      value={customer.addressLine1}
                      onChange={(e) =>
                        updateCustomer({ addressLine1: e.target.value })
                      }
                      id="addressLine1Input"
                      label="Address Line 1"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="addressLine2Input">
                      Address Line 2
                    </InputLabel>
                    <OutlinedInput
                      value={customer.addressLine2}
                      onChange={(e) =>
                        updateCustomer({ addressLine2: e.target.value })
                      }
                      id="addressLine2Input"
                      label="Address Line 2"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="cityInput">City</InputLabel>
                    <OutlinedInput
                      value={customer.city}
                      onChange={(e) => updateCustomer({ city: e.target.value })}
                      id="cityInput"
                      label="City"
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="stateInput">State</InputLabel>
                    <Select
                      id="stateInput"
                      label="State"
                      value={customer.state}
                      onChange={(e) =>
                        updateCustomer({ state: e.target.value })
                      }
                    >
                      {states.map(({ name, abbreviation }) => (
                        <MenuItem key={abbreviation} value={abbreviation}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="zipInput">Zip Code</InputLabel>
                    <OutlinedInput
                      value={customer.zip}
                      onChange={(e) => updateCustomer({ zip: e.target.value })}
                      id="zipInput"
                      label="Zip Code"
                    />
                  </FormControl>
                  <Divider />
                  <FormControl>
                    <InputLabel htmlFor="notesInput">Notes</InputLabel>
                    <OutlinedInput
                      value={customer.notes}
                      onChange={(e) =>
                        updateCustomer({ notes: e.target.value })
                      }
                      multiline
                      minRows={3}
                      id="notesInput"
                      label="Notes"
                    />
                  </FormControl>
                </form>
                <div className={utilStyle.controlHeader}>
                  <div className={utilStyle.controlHeaderButtonContainer}>
                    <Button
                      color="success"
                      variant="contained"
                      startIcon={<Save />}
                      disabled={!canSave()}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      color="error"
                      variant="outlined"
                      startIcon={<Delete />}
                      disabled={!id}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Divider orientation="vertical" />
              <Divider />
            </div>

            <div className={pageStyle.customerActivityPanel}>
              <List>
                {customerActivity.map((activity) => {
                  switch (activity.type) {
                    case "proposal":
                      return (
                        <ListItemButton
                          key={activity.id}
                          component={Link}
                          to={`/proposals/details?id=${activity.id}`}
                        >
                          <ListItemIcon>
                            <Assignment />
                          </ListItemIcon>
                          <ListItemText
                            primary="Proposal"
                            secondary={formatMoney(activity.totalAmount)}
                          />
                          <Chip
                            label={activity.status}
                            color="primary"
                            variant="filled"
                          />
                        </ListItemButton>
                      );
                    case "salesCall":
                      return (
                        <ListItemButton key={activity.id}>
                          <ListItemIcon>
                            <AccessTime />
                          </ListItemIcon>
                          <ListItemText
                            primary="Sales Call"
                            secondary={`Thursday, June 6, 2024, 11:00 AM`}
                          />
                        </ListItemButton>
                      );
                    case "photo":
                      return (
                        <ListItemButton
                          component={Link}
                          to={`/photos/details?id=${activity.id}&cid=${id}`}
                          key={activity.id}
                        >
                          <ListItemIcon>
                            <MUIPhoto />
                          </ListItemIcon>
                          <img
                            className={pageStyle.customerPhoto}
                            src={activity.photoData}
                            style={{ maxWidth: "400px" }}
                          />
                        </ListItemButton>
                      );
                  }
                })}
              </List>
              <div className={utilStyle.controlHeader}>
                <div className={utilStyle.controlHeaderButtonContainer}>
                  <Button
                    onClick={(e) => setNewActivityAnchorEl(e.currentTarget)}
                    variant="contained"
                    startIcon={<Add />}
                    disabled={!id}
                  >
                    New
                  </Button>

                  <Menu
                    anchorEl={newActivityAnchorEl}
                    open={newActivityOpen}
                    onClose={() => setNewActivityAnchorEl(null)}
                    sx={{ width: 320, maxWidth: "100%" }}
                  >
                    <MenuList>
                      <MenuItem
                        component={Link}
                        to={`/proposals/details?cid=${id}`}
                      >
                        <ListItemIcon>
                          <Assignment fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Proposal</ListItemText>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <AccessTime fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Sales Call</ListItemText>
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to={`/photos/details?cid=${id}`}
                      >
                        <ListItemIcon>
                          <MUIPhoto fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Photo</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CustomerDetailsPage;
