import { database } from "..";
import Setting from "../model/settings";
import { Q } from "@nozbe/watermelondb";

export async function getDefaultTerms(): Promise<string> {
  const settings = await database
    .get<Setting>("settings")
    .query(Q.where("key", "terms"))
    .fetch();
  if (settings.length !== 0 && settings[0].value) {
    return settings[0].value;
  }
  return "";
}

export async function setDefaultTerms(terms: string): Promise<void> {
  await database.write(async () => {
    const termsSetting = await database
      .get<Setting>("settings")
      .query(Q.where("key", "terms"))
      .fetch();
    if (termsSetting.length !== 0 && termsSetting[0]) {
      termsSetting[0].update((s) => {
        s.value = terms;
      });
    } else {
      await database.get<Setting>("settings").create((s) => {
        s.key = "terms";
        s.value = terms;
      });
    }
  });
}
