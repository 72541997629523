import apiRequest from "./requests";
import { synchronize } from "@nozbe/watermelondb/sync";
import { database } from "..";
import { AxiosError } from "axios";

export async function syncDatabase(token: string) {
  await synchronize({
    database,
    pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
      const urlParams = `last_pulled_at=${lastPulledAt}&schema_version=${schemaVersion}&migration=${encodeURIComponent(
        JSON.stringify(migration)
      )}`;
      try {
        const response = await apiRequest.get(`/sync/pull?${urlParams}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const { changes, timestamp } = await response.data;
        return { changes, timestamp };
      } catch (e: any) {
        if (e.response?.status === 401) {
          sessionStorage.removeItem("token");
          window.location.reload();
        }
      }
      throw "Sync Error";
    },
    pushChanges: async ({ changes, lastPulledAt }) => {
      const response = await apiRequest.post(
        `/sync/push?last_pulled_at=${lastPulledAt}`,
        changes,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    },
  });
}
