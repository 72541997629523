import { database } from "..";
import Photo from "../model/photo";
import Proposal from "../model/proposal";

export type ActivityPhoto = {
  type: "photo";
  id: string;
  photoData: string;
  createdAt: number;
};

export type ActivityProposal = {
  type: "proposal";
  id: string;
  createdAt: number;
  totalAmount: number;
  status: string;
};

export type ActivitySalesCall = {
  type: "salesCall";
  id: string;
  createdAt: number;
  // TODO : Add SalesCall
};

export type APIActivity = ActivityPhoto | ActivityProposal | ActivitySalesCall;

export type APIActivityDay = {
  day: string;
  activities: APIActivity[];
};

export async function getActivities(): Promise<APIActivityDay[]> {
  const photos: ActivityPhoto[] = (
    await database.get<Photo>("photos").query().fetch()
  ).map((p) => ({
    type: "photo",
    id: p.id || "",
    photoData: p.photoData || "",
    createdAt: p.createdAt || 0,
  }));
  const proposals: ActivityProposal[] = (
    await database.get<Proposal>("proposals").query().fetch()
  ).map((p) => ({
    type: "proposal",
    id: p.id || "",
    createdAt: p.createdAt || 0,
    totalAmount: p.totalAmount || 0,
    status: p.status || "",
  }));
  const allActivities = [...photos, ...proposals].sort(
    (a, b) => (a.createdAt || 0) - (b.createdAt || 0)
  );
  const obj: any = Object; // Because TypeScript didn't like using Object directly
  const byDays = obj.groupBy(
    allActivities,
    ({ createdAt }: { createdAt?: number }) => {
      const date = new Date(createdAt || 0);
      return `${date.getFullYear()}-${String(date.getMonth()).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    }
  );
  const newActivities: APIActivityDay[] = [];
  for (const [key, val] of Object.entries(byDays)) {
    const v: any = val;
    newActivities.push({ day: key, activities: v });
  }
  return newActivities.sort((a, b) => ((a.day || "") < (b.day || "") ? 1 : -1));
}
