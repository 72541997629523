import { database } from "..";
import Tax from "../model/tax";

export type APITax = {
  id?: string;
  title: string;
  percentRate: number;
};

export function emptyTax(): APITax {
  return {
    title: "",
    percentRate: 0,
  };
}

function dbTaxToAPITax(t: Tax): APITax {
  return {
    id: t.id,
    title: t.title || "",
    percentRate: t.percentRate || 0.0,
  };
}

export async function getTax(taxID?: string): Promise<APITax> {
  if (!taxID) {
    return emptyTax();
  }
  const tax = await database.get<Tax>("taxes").find(taxID);
  return dbTaxToAPITax(tax);
}

export async function saveTax(tax: APITax): Promise<APITax> {
  const databaseWrite = (t: Tax) => {
    t.title = tax.title;
    t.percentRate = tax.percentRate;
  };
  let savedTaxID: string | undefined = tax.id;
  if (!tax.id) {
    await database.write(async () => {
      const savedTax = await database.get<Tax>("taxes").create(databaseWrite);
      savedTaxID = savedTax.id;
    });
  } else {
    await database.write(async () => {
      const savedTax = await database.get<Tax>("taxes").find(tax.id || "");
      await savedTax.update(databaseWrite);
    });
  }
  return getTax(savedTaxID);
}

export async function deleteTax(taxId: string): Promise<void> {
  await database.write(async () => {
    const tax = await database.get<Tax>("taxes").find(taxId);
    await tax.markAsDeleted();
  });
}

export async function getAllTaxes(): Promise<APITax[]> {
  const taxCollection = database.get<Tax>("taxes");
  let query = taxCollection.query();
  const dbTaxes: Tax[] = await query.fetch();
  const taxes: APITax[] = dbTaxes.map(dbTaxToAPITax);
  return taxes;
}
