import { Button, Divider, FormControl } from "@mui/material";
import TopNav from "../components/TopNav";
import { Save } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  getAllProducts,
  getInvoiceLines,
  QuickbooksInvoice,
  QuickbooksProduct,
} from "../api/quickbooks";
import { CSVLink } from "react-csv";

function QuickbooksPage() {
  const [start, setStart] = useState<number | undefined>(undefined);
  const [end, setEnd] = useState<number | undefined>(undefined);

  const [products, setProducts] = useState<QuickbooksProduct[]>([]);

  const getExportProducts = async () => {
    const products = await getAllProducts();
    setProducts(products);
    return true;
  };

  const [invoices, setInvoices] = useState<QuickbooksInvoice[]>([]);

  const getExportInvoices = async () => {
    const invoices = await getInvoiceLines(
      start ? new Date(start) : undefined,
      end ? new Date(end) : undefined
    );
    setInvoices(invoices);
    return true;
  };

  return (
    <div className="page">
      <TopNav />
      <main>
        <div className={utilStyle.controlHeader}>
          <div className={utilStyle.controlHeaderButtonContainer}>
            <CSVLink
              onClick={getExportProducts}
              asyncOnClick={true}
              data={products}
              filename="products.csv"
            >
              <Button variant="contained" startIcon={<Save />}>
                Export Products
              </Button>
            </CSVLink>
          </div>
          <Divider />
          <div className={utilStyle.controlHeaderButtonContainer}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={(e) => setStart(e ? e.unix() * 1000 : undefined)}
                  value={start ? dayjs(start) : undefined}
                  label="Start Date"
                />
              </LocalizationProvider>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  onChange={(e) => setEnd(e ? e.unix() * 1000 : undefined)}
                  value={end ? dayjs(end) : undefined}
                  label="End Date"
                />
              </FormControl>
            </LocalizationProvider>
          </div>
          <div className={utilStyle.controlHeaderButtonContainer}>
            <CSVLink
              onClick={getExportInvoices}
              asyncOnClick={true}
              data={invoices}
              filename="invoices.csv"
            >
              <Button variant="contained" startIcon={<Save />}>
                Export Invoices
              </Button>
            </CSVLink>
          </div>
        </div>
      </main>
    </div>
  );
}

export default QuickbooksPage;
