import { useEffect, useState } from "react";
import TopNav from "../components/TopNav";
import { Link } from "react-router-dom";
import {
  List,
  Avatar,
  ListItemText,
  ListItemAvatar,
  FormControl,
  Button,
  ListItemButton,
  Chip,
  ListItemIcon,
  Menu,
  Checkbox,
  ListItem,
  Slider,
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import utilStyle from "./Utils.module.css";
import { formatMoney } from "../utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { APIProposal, getAllProposals } from "../api/proposals";

function ProposalListPage() {
  const [proposalsData, setProposalsData] = useState<APIProposal[]>([]);

  useEffect(() => {
    getAllProposals().then((v) => setProposalsData(v));
  }, []);

  // Dropdown menus
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const filterOpen = !!filterAnchorEl;

  return (
    <div className="page">
      <TopNav />
      <div>
        <main>
          <div className={utilStyle.controlHeader}>
            <div className={utilStyle.controlHeaderButtonContainer}>
              <Button
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                variant="outlined"
                startIcon={<FilterList />}
              >
                Filter
              </Button>
              <Menu
                anchorEl={filterAnchorEl}
                open={filterOpen}
                onClose={() => setFilterAnchorEl(null)}
                sx={{ width: 400, maxWidth: "100%" }}
              >
                <List sx={{ width: 400, maxWidth: "100%" }}>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>Status</ListItemText>
                    <ListItemButton dense>
                      <ListItemIcon>
                        <Checkbox edge="start" disableRipple />
                      </ListItemIcon>
                      <ListItemText primary="Lead" />
                    </ListItemButton>

                    <ListItemButton dense>
                      <ListItemIcon>
                        <Checkbox edge="start" disableRipple />
                      </ListItemIcon>
                      <ListItemText primary="Quoted" />
                    </ListItemButton>

                    <ListItemButton dense>
                      <ListItemIcon>
                        <Checkbox edge="start" disableRipple />
                      </ListItemIcon>
                      <ListItemText primary="Won" />
                    </ListItemButton>

                    <ListItemButton dense>
                      <ListItemIcon>
                        <Checkbox edge="start" disableRipple />
                      </ListItemIcon>
                      <ListItemText primary="Done" />
                    </ListItemButton>
                  </List>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>Total</ListItemText>
                    <ListItem>
                      <Slider value={[0, 100]} />
                    </ListItem>
                  </List>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>Created</ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker label="Start Date" />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker label="End Date" />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                  <List>
                    <ListItemText sx={{ paddingLeft: 2 }}>
                      Last Edited
                    </ListItemText>
                    <ListItem>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker label="Start Date" />
                        </LocalizationProvider>
                      </FormControl>
                    </ListItem>
                    <ListItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                          <DatePicker label="End Date" />
                        </FormControl>
                      </LocalizationProvider>
                    </ListItem>
                  </List>
                </List>
              </Menu>
              {/* <Button variant="outlined" startIcon={<Download />}>
                Export
              </Button> */}
            </div>
          </div>
          <List>
            {proposalsData.map((proposal) => (
              <ListItemButton
                component={Link}
                to={`/proposals/details?id=${proposal.id}`}
                key={proposal.id}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#606060" }}>
                    {proposal.firstName && proposal.firstName[0].toUpperCase()}
                    {proposal.lastName && proposal.lastName[0].toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${proposal.firstName} ${proposal.lastName}`}
                  secondary={`${proposal.addressLine1} ${proposal.city}, ${proposal.state}`}
                />
                <Chip
                  label={formatMoney(proposal.totalAmount || 0)}
                  color="success"
                  variant="outlined"
                />
              </ListItemButton>
            ))}
          </List>
        </main>
      </div>
    </div>
  );
}

export default ProposalListPage;
